<template>
    <div>
        <div class="kt-portlet__head">
            <form class="isprint-filter" autocomplete="off">                
                <div class="form-group">
                    <label>Codice articolo</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Codice Articolo" type="text" style="width:150px" v-model="search.CODICE_ARTICOLO.VALUE">
                </div>
                <div class="form-group">
                    <label>Descrizione</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Descrizione" type="text" style="width:150px" v-model="search.DESCRIZIONE.VALUE">
                </div>              
            </form>
        </div>
        <div class="isprint-grid-container" >    
            <table class="isprint-grid shadow" >
                <thead class="isprint-grid-thead" >
                <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                    <tr style="left: 0px;background:#4e73df;color:#fff">
                        <th  style="width:15%"><span>Codice articolo</span></th>
                        <th  style="width:40%"><span>Descrizione</span></th>                        
                        <th  style="width:45%"><span >Azioni</span></th>
                    </tr>
                </thead>
                <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                    <tr style="left: 0px;height:50px"  v-for="articolo in paginatedList.arr" v-bind:key="articolo.ID_ARTICOLO">
                        <td  style="width:15%">
                            <kbd>{{ articolo.CODICE_ARTICOLO }}</kbd>
                        </td>
                        <td  style="width:40%">
                            <div style="float:left" class="mt-1 mr-2"><i class="fal fa-shopping-cart fa-fw fa-2x"></i></div> <div style="float:left"><strong>{{articolo.DESCRIZIONE}}</strong></div>
                        </td>                                             
                        <td  style="width:45%">
                            <button class="btn btn-primary float-right btn-sm" v-on:click="modificaArticolo(articolo)">Centri ricavo</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
        </div>


        <div class="modal fade" id="popUpArticolo" data-backdrop="true" role="dialog"  aria-modal="true" >
            <div class="modal-dialog" style="min-width:800px;"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-primary">
                        <h4 style="color:#fff" class="modal-title"><i class="fal fa-shopping-cart fa-fw"></i> Articolo</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="tab-content">
                            <div class="kt-portlet__head" >
                                    <form class="isprint-filter" autocomplete="off">                
                                        <div class="form-group">
                                            <label>Centro ricavo</label><br/>
                                            <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Descrizione" type="text" v-model="searchCr.DESCRIZIONE.VALUE">
                                        </div>
                                    </form>
                                </div>
                                <hr/>
                                <div class="row">
                                    <div class="col-md-4" v-on:click="setCentroRicavo(centroRicavo)" v-for="centroRicavo in paginatedListCr.arr" v-bind:key="centroRicavo.ID_CENTRO_RICAVO" style="margin-bottom:10px">
                                        <div class="card lift-panel shadow " v-bind:style="getStyleCentroRicavo(centroRicavo)" style="height:45px;">
                                            <div class="card-body d-flex h-100" style="padding:.25rem;padding-left:.5rem;padding-right:.5rem;font-weight:bold">
                                                <div class="justify-content-center align-self-center" style="font-size:14px">
                                                    <i class="fal fa-tag fa-fw"></i> {{centroRicavo.DESCRIZIONE}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                                <pagine v-model="searchCr.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListCr.count"  :numpagine="searchCr.PAGINATION.LENGTH"></pagine>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

import Pagine from '@/components/utils/Pagine.vue'


export default {
        name:"Articoli",
        components:{
            Pagine,
        },
        data:function(){
        return{
            filters:global.filters,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                CODICE_ARTICOLO:{TYPE:'TEXT',VALUE:''}, 
            },
            searchCr:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:18},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            arrayArticoli : new Array(),
            arrayCentriRicavo : new Array(),
            articoloSelezionato : {centriRicavo:new Array()}
        }
    },
    methods: {
        getArticoli : function(){
            utils.ajax('/nic/articoli',{}, (response) => {
                this.arrayArticoli = response.data;
            });
        },      
        modificaArticolo : function(articolo){
            utils.ajax("nic/articolo",articolo , (response) => {
                this.articoloSelezionato = response.data;
                var modal = $('#popUpArticolo');
                modal.modal('show');
            });
        },

       
        setCentroRicavo : function(centroRicavo){
            console.log("ciaone");
            var check = global.utils.array.select( this.articoloSelezionato.centriRicavo,"FK_ID_CENTRO_RICAVO",centroRicavo.ID_CENTRO_RICAVO,1).length;
            if (check == 1){
                for (var i = 0 ; i < this.articoloSelezionato.centriRicavo.length ; i++){
                    if (this.articoloSelezionato.centriRicavo[i].FK_ID_CENTRO_RICAVO == centroRicavo.ID_CENTRO_RICAVO){
                        this.articoloSelezionato.centriRicavo.splice(i,1);
                        break;
                    }
                }
            }
            else{
                this.articoloSelezionato.centriRicavo.push({FK_ID_CENTRO_RICAVO:centroRicavo.ID_CENTRO_RICAVO,ID_ARTICOLO:this.articoloSelezionato.ID_ARTICOLO});
            }
            global.utils.ajax('nic/articolo/centroricavo/update',{FK_ID_CENTRO_RICAVO:centroRicavo.ID_CENTRO_RICAVO,ID_ARTICOLO:this.articoloSelezionato.ID_ARTICOLO}, (response) => {
                if (response.esito == 0){
                    global.utils.alert.success(response.messaggio);
                }
            });
        },
        getStyleCentroRicavo : function(centroRicavo){
            var check = global.utils.array.select( this.articoloSelezionato.centriRicavo,"FK_ID_CENTRO_RICAVO",centroRicavo.ID_CENTRO_RICAVO,1).length;
            if (check == 1){
                return {background:'#1cc88a',color:"#fff"};
            }
            return {};
        
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayArticoli,this.search);
            return arr;
        },
        paginatedListCr(){
            if (this.arrayCentriRicavo == undefined || this.arrayCentriRicavo == null)
                return new Array();
            var arr = global.utils.inGrid(this.arrayCentriRicavo,this.searchCr);
            return arr;
        },
    },
    created : function(){
        utils.ajax('/nic/articoli/startup',{}, (response) => {
            this.arrayArticoli = response.data.ARTICOLI;
            this.arrayCentriRicavo = response.data.CENTRI_RICAVO;
        });    
    },
}
</script>