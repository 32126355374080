<template>
    <div>
    <div class="kt-portlet__head">
        <form class="isprint-filter" autocomplete="off">                
            <button class="btn btn-success float-right btn-sm" v-on:click="caricaFlusso()" style="margin-top:26px"><i class="fal fa-plus"></i> Carica flusso</button>
        </form>
    </div>
    <div class="isprint-grid-container" >    
        <table class="isprint-grid shadow" >
            <thead class="isprint-grid-thead" >
            <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                <tr style="left: 0px;background:#1cc88a;color:#fff">
                    <th  style="width:10%"><span>ID_FLUSSO</span></th>
                    <th  style="width:30%"><span>Data caricamento</span></th>
                    <th  style="width:30%"><span >Data primo ordine</span></th>
                    <th  style="width:20%"><span >Data ultimo ordine</span></th>
                    <th  style="width:10%"><span >Azioni</span></th>
                </tr>
            </thead>
            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                <tr style="left: 0px;height:50px" v-bind:key="flusso.ID_FLUSSO_CARICATO"  v-for="flusso in paginatedList.arr">
                    <td  style="width:10%">
                        <kbd>{{flusso.ID_FLUSSO_CARICATO}}</kbd>
                    </td>
                    <td  style="width:30%">
                        {{filters.formatDateTime(flusso.DATA_CARICAMENTO)}}
                    </td>
                    <td  style="width:30%">
                        {{filters.formatDate(flusso.DATA_PRIMO_ORDINE)}}
                    </td>
                    <td  style="width:20%">
                        {{filters.formatDate(flusso.DATA_ULTIMO_ORDINE)}}
                    </td>
                    <td  style="width:10%">
                        <button class="btn btn-danger btn-sm float-right" v-on:click="deleteFlusso(flusso)"><i class="fal fa-trash-alt"></i></button>
                    </td>
                    
                </tr>
            </tbody>
        </table>
        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
    </div>



    <div class="modal fade" id="popUpFlusso" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:574px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-warning" style="background:#717384;">
                    <h4 style="color:#fff" class="modal-title"><i class="fal fa-euro-sign fa-fw"></i> Nuovo Flusso</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <Upload @onsuccess="successCaricamento()" :args="args"></Upload>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>


import Upload from '@/components/utils/Upload.vue'
import Pagine from '@/components/utils/Pagine.vue'


export default {
    name:"Flussi",
    components:{
        Pagine,
        Upload
    },
    data:function(){
        return{
            filters:global.filters,
            args:{
                modalita:'NIC_uploadFlusso'
            },
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
            },
            arrayFlussiCaricati : new Array(),
            moduloSelezionato : {}
        }
    },
    methods: {
        caricaFlusso : function(){
            var modal = $('#popUpFlusso');
            modal.modal('show');  
        },
        successCaricamento : function(){
            var modal = $('#popUpFlusso');
            modal.modal('hide'); 
            global.utils.ajax('/nic/flussicaricati',{}, (response) => {
                this.arrayFlussiCaricati = response.data.reverse();
            });  
        },
        deleteFlusso : function(flusso){
            utils.alert.confirm("Sei sicuro di voler eliminare il flusso selezionato?",()=>{
               utils.ajax('nic/flussocaricato/delete',{ID_FLUSSO_CARICATO:flusso.ID_FLUSSO_CARICATO}, (response) => {
                   if (response.esito == 0){
                       utils.alert.success(response.messaggio);
                       global.utils.ajax('/nic/flussicaricati',{}, (response) => {
                            this.arrayFlussiCaricati = response.data.reverse();
                        });       
                    }
               });
           },this.$root);
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayFlussiCaricati,this.search);
            return arr;
        },
    },
    created : function(){
        global.utils.ajax('/nic/flussicaricati',{}, (response) => {
            this.arrayFlussiCaricati = response.data.reverse();
        });        
    },
}
</script>