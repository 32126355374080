<template>
    <div>
    
        <div class="kt-portlet__head">
            <form class="isprint-filter" autocomplete="off">                
                <div class="form-group">
                    <label>Username</label><br/>
                    <input class="form-control form-control-sm" autocomplete="off" naem="asdas" placeholder="Username" type="text" v-model="search.USERNAME.VALUE">
                </div>   
                <div class="form-group">
                    <label>Cognome</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Cognome" type="text" v-model="search.COGNOME.VALUE">
                </div>   
                <div class="form-group">
                    <label>Nome</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Nome" type="text" v-model="search.NOME.VALUE">
                </div>   
                <div class="form-group">
                    <label>Email</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Email" type="text" v-model="search.EMAIL.VALUE">
                </div>  
                <div class="form-group" v-show="utenteCorrente.FK_ID_LIVELLO <= livelloMaxFiltroSocieta">
                    <label>Società</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Società" type="text" v-model="search.DENOMINAZIONE.VALUE">
                </div>       
                <button v-on:click="nuovoUtente()" class="btn btn-success btn-sm float-right" style="margin-top:32px"><i class="fas fa-plus fa-fw"></i>Nuovo utente</button>                          
            </form>
        </div>

        <div class="isprint-grid-container" >    
            <table class="isprint-grid shadow" >
                <thead class="isprint-grid-thead" >
                    <tr style="left: 0px;background:#69d0ff"  >
                        <th  style="width:29%"><span>Username</span></th>
                        <th  style="width:15%"><span >Email</span></th>
                        <th  style="width:10%"><span >Data creazione</span></th>
                        <th  style="width:4%"><span >Stato</span></th>
                        <th  style="width:10%"><span >Livello</span></th>
                        <th  style="width:15%"><span >Gruppi</span></th>
                        <th  style="width:17%"><span >Azioni</span></th>
                    </tr>
                </thead>
                <tbody class="isprint-grid-tbody" >
                    <tr dstyle="left: 0px;"  v-for="utente in paginatedList.arr" v-bind:key="utente.ID_UTENTE">
                        <td  style="width:29%"><div >{{utente.ID_UTENTE}} - {{utente.USERNAME}}</div><div>{{utente.COGNOME}} {{utente.NOME}}</div></td>
                        <td  style="width:15%"><span ><a target="_blank" :href="'mailto:'+utente.EMAIL">{{utente.EMAIL}}</a></span></td>
                        <td  style="width:10%"><span >{{filters.formatDateTime(utente.DATA_CREAZIONE)}}</span></td>
                        <td  style="width:4%">
                            <ISwitch @toggle="updateStatoUtente(utente)" :size="'sm'" v-model="utente.STATO" style="margin-top:6px"></ISwitch>
                        </td>
                        <td  style="width:10%"><span >{{utente.LIVELLO}}</span></td>
                        <td  style="width:15%"><span >{{utente.GRUPPI}}</span></td>
                        <td  style="width:17%">
                            <button class="btn btn-success btn-sm ml-2" data-skin="dark"  data-toggle="kt-tooltip" data-placement="top"  data-original-title="Login" v-on:click="login(utente)"><i class="fas fa-sign-in-alt"></i></button>
                           <!-- 
                            <button v-show="utenteCorrente.FK_ID_LIVELLO == 1" class="btn btn-info btn-sm ml-2" data-skin="dark"  data-toggle="kt-tooltip" data-placement="top"  data-original-title="Gruppi" v-on:click="gruppiUtente(utente)"><i class="fa fa-fw fa-list"></i></button>
                            -->
                            <button class="btn btn-primary btn-sm ml-2" data-skin="dark" data-toggle="kt-tooltip" data-placement="top"  data-original-title="Modifica" v-on:click="funzionalitaUtente(utente)"><i class="fa fa-fw fa-puzzle-piece"></i></button>
                            <button class="btn btn-warning btn-sm ml-2" data-skin="dark" data-toggle="kt-tooltip" data-placement="top"  data-original-title="Modifica" v-on:click="modificaUtente(utente)"><i class="fa fa-fw fa-pencil-alt"></i></button>
                            <button class="btn btn-secondary btn-sm ml-2" data-skin="dark" data-toggle="kt-tooltip" data-placement="top"  data-original-title="Password" v-on:click="resetPassword(utente)"><i class="fa fa-fw fa-key"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>     
            <pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></pagine>
        </div>
        <div class="modal fade" id="modalPassword" tabindex="-1" role="dialog"  aria-modal="true" >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title" id="exampleModalLabel"><i class="fa fa-fw fa-key"></i> Reset Password</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Nuova password</label>
                            <input type="password" class="form-control" placeholder="Nuova password" v-model="utenteSelezionato.PASSWORD">
                        </div>
                        <div class="form-group">
                            <label>Conferma password</label>
                            <input type="password" class="form-control" placeholder="Conferma nuova password" v-model="utenteSelezionato.CONFERMA_PASSWORD">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Annulla</button>
                        <button type="button" class="btn btn-success" v-on:click="doResetPassword()">Salva</button>
                    </div>
                </div>
            </div>
        </div> 

        <div class="modal fade" id="popUpGruppiUtente"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog modal-lg modal-header-info" role="document">
                <div class="modal-content">
                    <div class="modal-header  modal-header-info">
                        <h5 class="modal-title">Gruppi utente {{utenteSelezionato.USERNAME}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12" >
                            <div class="row" >
                                <div class="isprint-filter" style="width:100%">            
                                    <div class="form-group">
                                        <label>Descrizione</label><br/>
                                        <input class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchGruppiUtente.DESCRIZIONE.VALUE">
                                    </div>   
                                </div>
                            </div>
                            <div class="row" >
                                <ul class="list-group" style="width:100%">
                                    <li class="list-group-item isprint-list" v-for="gruppo in paginatedListGruppiUtente.arr" v-bind:key="gruppo.ID_GRUPPO" >
                                        <div class="col-md-9">{{gruppo.DESCRIZIONE}}</div>
                                        <div class="col-md-3 float-right"  >
                                            <ISwitch @toggle="updateGruppoUtente(gruppo,$event)" :size="'sm'" v-model="gruppo.SELEZIONATO" class="pull-right"></ISwitch>
                                        </div>
                                    </li>
                                </ul>
                            </div>                       
                        </div>

                        <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded" >
                            <Pagine v-model="searchGruppiUtente.PAGINATION.PAGE_NUMBER" :numpagine="searchGruppiUtente.PAGINATION.LENGTH"></Pagine>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Chiudi</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="popUpFunzionalitaUtente"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">Funzionalità utente {{utenteSelezionato.USERNAME}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <div class="row" >
                                <div class="isprint-filter" style="width:100%">            
                                    <div class="form-group">
                                        <label>Descrizione</label><br/>
                                        <input class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchFunzionalitaUtente.DESCRIZIONE.VALUE">
                                    </div>   
                                </div>
                            </div>
                            <div class="row" >
                                <ul class="list-group" style="width:100%">
                                    <li class="list-group-item isprint-list" v-for="funzionalita in paginatedListFunzionalitaUtente.arr" v-bind:key="funzionalita.ID_FUNZIONALITA" >
                                        <div class="col-md-10" style="margin-top:-4px">{{funzionalita.DESCRIZIONE}}</div>
                                        <div class="col-md-2 float-right" style="margin-top:-4px" >
                                            <ISwitch @toggle="updateFunzionalitaUtente(funzionalita)" :size="'sm'" v-model="funzionalita.SELEZIONATO" class="pull-right"></ISwitch>
                                        </div>
                                    </li>
                                </ul>
                            </div>                       
                        </div>

                        <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded" >
                            <Pagine v-model="searchFunzionalitaUtente.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListFunzionalitaUtente.count" :numpagine="searchFunzionalitaUtente.PAGINATION.LENGTH"></Pagine>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Chiudi</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="popUpUtente"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog modal-lg"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">{{utenteSelezionato.ID_UTENTE == 0 ? 'Nuovo' : 'Modifica'}} utente</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12" style="margin-top:10px">
                            <div class="row" >
                                <div class="col-md-12 form-group">
                                    <label>Username</label>
                                    <input v-model="utenteSelezionato.USERNAME" class="input-sm form-control input-sm" placeholder="Username" type="text">
                                </div>
                                <div class="col-md-6 form-group" v-show="utenteSelezionato.ID_UTENTE == 0">
                                    <label>Password</label>
                                    <input v-model="utenteSelezionato.PASSWORD" class="input-sm form-control input-sm" placeholder="Password" type="password">
                                </div>
                                <div class="col-md-6 form-group" v-show="utenteSelezionato.ID_UTENTE == 0">
                                    <label>Conferma password</label>
                                    <input v-model="utenteSelezionato.CONFERMA_PASSWORD" class="input-sm form-control input-sm" placeholder="Password" type="password">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Email</label>
                                    <input v-model="utenteSelezionato.EMAIL" class="input-sm form-control input-sm" placeholder="Email" type="text">
                                </div>
                                 <div class="col-md-6 form-group" v-show="utenteSelezionato.ID_UTENTE == 0">
                                    <label>Conferma email</label>
                                    <input v-model="utenteSelezionato.CONFERMA_EMAIL" class="input-sm form-control input-sm" placeholder="Conferma Email" type="text">
                                </div>
                                 <div class="col-md-6 form-group" v-show="utenteSelezionato.ID_UTENTE == 0">
                                    <label>Nome</label>
                                    <input v-model="utenteSelezionato.NOME" class="input-sm form-control input-sm" placeholder="Nome" type="text">
                                </div>
                                 <div class="col-md-6 form-group" v-show="utenteSelezionato.ID_UTENTE == 0">
                                    <label>Cognome</label>
                                    <input v-model="utenteSelezionato.COGNOME" class="input-sm form-control input-sm" placeholder="Cognome" type="text">
                                </div>
                            </div>                       
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times fa-fw"></i>Chiudi</button>
                        <button type="button" class="btn btn-success" v-on:click="salvaUtente()"><i class="fa fa-check fa-fw"></i>Salva</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'


export default {
    name:"Utenti",
    components:{
        Pagine,
        ISwitch,
    },
    data: function () {
        return {   
            apiStartup:'nic/utenti',
            livelloMaxFiltroSocieta: 2,
            filters:global.filters,
            arrayUtenti: new Array(),
            arrayCampiPopUpUtente : new Array(),
            selectedMenuItem:{},
            utenteSelezionato:{},
            utenteCorrente:{},
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                USERNAME:{TYPE:'TEXT',VALUE:''},
                COGNOME:{TYPE:'TEXT',VALUE:''},
                NOME:{TYPE:'TEXT',VALUE:''},
                EMAIL:{TYPE:'TEXT',VALUE:''},
                DENOMINAZIONE : {TYPE:'TEXT',VALUE:''}
            },
            searchGruppiUtente:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:6},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            arrayGruppiUtente : new Array(),
            searchFunzionalitaUtente:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:6},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            arrayFunzionalitaUtente : new Array()
        }
    },
    methods: {
        getUtenti: function () {
           // utils.ajax('core/basic/any',{IN:"6",className:'CustomFormElement'}, (response) => {
            //    this.arrayCampiPopUpUtente = response.data;
                utils.ajax(this.apiStartup,{}, (response) => {
                    this.arrayUtenti = response.data;
                    //this.arrayCampiPopUpUtente = response.data.FORM;
                }); 
          //  });
        },
        nuovoUtente:function(){
            this.utenteSelezionato = utils.nuovoElemento(this.utenteSelezionato,{ID_UTENTE:0,className:"Utente",USERNAME:''},"popUpUtente");
        },
        modificaUtente : function(utente){
            utils.ajax('core/admin/utente',{ID_UTENTE:utente.ID_UTENTE}, (response) => {
                this.utenteSelezionato = response.data;
                var modal = $('#popUpUtente');
                modal.modal('show');  
            });
        },
        salvaUtente : function(){
            utils.salvaElemento("nic/utente/",this.utenteSelezionato,"ID_UTENTE","popUpUtente",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getUtenti();
                }
            });
        },
        resetPassword : function(utente){
            this.utenteSelezionato = utils.clone(utente);
            var modal = $('#modalPassword');
            modal.modal('show');  
        },
        doResetPassword : function(){
            var modal = $('#modalPassword');
            modal.modal('hide'); 
            utils.ajax('core/admin/utente/password/reset',this.utenteSelezionato, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }  
            }); 
        },
        updateStatoUtente : function(utente){
            setTimeout(() => {
                utils.ajax('core/utente/stato/update',utente, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        var modal = $('#popUpUtente');
                        modal.modal('hide');  
                    }  
                }); 
            },250);
        },
        gruppiUtente : function(utente){
            this.arrayGruppiUtente = new Array();
            utils.ajax("/core/utente/gruppi",utente, (response) => {
                this.utenteSelezionato = response.data.utente;
                for (var i = 0 ; i < response.data.gruppi.length ; i++){
                    var selezionato = utils.array.select(this.utenteSelezionato.gruppi,"ID_GRUPPO",response.data.gruppi[i]['ID_GRUPPO'],1).length == 1 ? 1 : 0;
                    var item = utils.clone(response.data.gruppi[i]);
                    item.SELEZIONATO = selezionato;
                    this.arrayGruppiUtente.push(item);
                }
                var modal = $('#popUpGruppiUtente');
                modal.modal('show');
            });
        },
        updateGruppoUtente:function(gruppo,event){
            utils.ajax("core/utente/gruppo/update",{ID_GRUPPO:gruppo.ID_GRUPPO,ID_UTENTE:this.utenteSelezionato.ID_UTENTE,SELEZIONATO:event}, (response) => {
                this.getUtenti();
            });
        },
        funzionalitaUtente : function(utente){
            this.arrayFunzionalitaUtente = new Array();
            utils.ajax("/nic/utente/funzionalita",utente, (response) => {
                this.utenteSelezionato = response.data.utente;
                for (var i = 0 ; i < response.data.funzionalita.length ; i++){
                    var selezionato = utils.array.select(this.utenteSelezionato.funzionalita,"ID_FUNZIONALITA",response.data.funzionalita[i]['ID_FUNZIONALITA'],1).length == 1 ? 1 : 0;
                    var item = utils.clone(response.data.funzionalita[i]);
                    item.SELEZIONATO = selezionato;
                    this.arrayFunzionalitaUtente.push(item);
                }
                var modal = $('#popUpFunzionalitaUtente');
                modal.modal('show');
            });
        },
        updateFunzionalitaUtente:function(funzionalita){
            setTimeout(() => {
                utils.ajax("nic/utente/funzionalita/update",{ID_FUNZIONALITA:funzionalita.ID_FUNZIONALITA,ID_UTENTE:this.utenteSelezionato.ID_UTENTE,SELEZIONATO:funzionalita.SELEZIONATO}, (response) => {
                    if (response.esito == 0){
                        this.arrayUtenti = response.data;
                        this.$forceUpdate();
                    }
                });                
            }, 300);
        },
        login : function(utente){
            utils.ajax('nic/utente/login',{ID_UTENTE:utente.ID_UTENTE}, (response) => {
                if (response.esito == 0){
                    window.location.reload();
                }
            });
        }
    },
    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayUtenti,this.search);
            return arr;
        },
        paginatedListGruppiUtente(){
            var arr = utils.inGrid(this.arrayGruppiUtente,this.searchGruppiUtente);
            return arr;
        },  
        paginatedListFunzionalitaUtente(){
            var arr = utils.inGrid(this.arrayFunzionalitaUtente,this.searchFunzionalitaUtente);
            return arr;
        }
    },
    created: function () {
        this.utenteCorrente = utils.clone(this.$root.utente);
        this.getUtenti();
    },
}
</script>