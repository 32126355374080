<template>
    <div>
    <div class="kt-portlet__head">
        <form class="isprint-filter" autocomplete="off">                
            <button class="btn btn-success float-right btn-sm" v-on:click="caricaFlusso()" style="margin-top:26px"><i class="fal fa-plus"></i> Carica flusso</button>
            <button class="btn btn-info float-right btn-sm" v-on:click="confrontaSelezionati()" style="margin-top:26px"><i class="fal fa-check-square"></i> Confronta selezionati</button>

        </form>
    </div>
    <div class="isprint-grid-container" >    
        <table class="isprint-grid shadow" >
            <thead class="isprint-grid-thead" >
            <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                <tr style="left: 0px;background:#cddc39;color:#fff">
                    <th  style="width:5%"><span>ID_FLUSSO</span></th>
                    <th  style="width:5%"><span>#</span></th>

                    <th  style="width:10%"><span>Data caricamento</span></th>
                    <th  style="width:30%"><span>Note</span></th>

                    <th  style="width:30%"><span >Data magazzino</span></th>
                    <th  style="width:10%"><span >Azioni</span></th>
                </tr>
            </thead>
            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                <tr style="left: 0px;height:50px" v-bind:key="flusso.ID_FLUSSO_CARICATO"  v-for="flusso in paginatedList.arr">
                    <td  style="width:5%">
                        <kbd>{{flusso.ID_FLUSSO_CARICATO}}</kbd> 
                    </td>
                    <td  style="width:5%">
                        <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="flusso.SELEZIONATO" style="width:20px;height:20px;margin-top:7px" >
                    </td>
                    <td  style="width:10%">
                        {{filters.formatDateTime(flusso.DATA_CARICAMENTO)}}
                    </td>
                    <td  style="width:30%">
                        {{flusso.NOTE}}
                    </td>
                    <td  style="width:30%">
                        {{filters.formatDate(flusso.DATA_MAGAZZINO)}}
                    </td>
                    <td  style="width:10%">
                        <button class="btn btn-danger btn-sm float-right" v-on:click="deleteFlusso(flusso)"><i class="fal fa-trash-alt"></i></button>
                    </td>
                    
                </tr>
            </tbody>
        </table>
        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
    </div>



    <div class="modal fade" id="popUpFlussoMagazzino" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:574px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-warning" style="background:#717384;">
                    <h4 style="color:#fff" class="modal-title"><i class="fal fa-euro-sign fa-fw"></i> Nuovo Flusso</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group mt-1 col-12" >
                        <label>Data</label>
                        <DatePicker placeholder="Data magazzino" autocomplete="off"  type="text" v-model="args.DATA_MAGAZZINO"></DatePicker>
                    </div>
                    <div class="form-group mt-1 col-12" >
                        <label>Note</label>
                        <textarea class="form-control" v-model="args.NOTE"></textarea>
                    </div>
                    <Upload @onsuccess="successCaricamento()" :args="args"></Upload>
                </div>
            </div>
        </div>
    </div>


    
    <div class="modal fade" id="popUpArticoliMagazzino" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:1000px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-warning" style="background:#717384;">
                    <h4 style="color:#fff" class="modal-title"><i class="fal fa-list fa-fw"></i> Confronto date</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">                   
                    <div class="kt-portlet__head" >
                        <form class="isprint-filter" autocomplete="off">                
                            <div class="form-group">
                                <label>Codice articolo</label><br/>
                                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Codice articolo" type="text" style="width:115px" v-model="searchArticoli.CODICE_ARTICOLO.VALUE">
                            </div>
                            <div class="form-group">
                                <label>Descrizione</label><br/>
                                <input class="form-control form-control-sm"  autocomplete="off"  placeholder="Descrizione" type="text" style="width:180px" v-model="searchArticoli.DESCRIZIONE.VALUE">
                            </div>
                        </form>
                    </div>
                    <div class="isprint-grid-modal-container" >    
                        <table class="isprint-grid-modal shadow" >
                            <thead class="isprint-grid-modal-thead" >
                                <tr style="left: 0px;background:#cddc39"  >
                                    <th  style="width:60%"><span>Articolo</span></th>
                                    <th  style="width:20%"><span>{{ data1 }}</span></th>
                                    <th  style="width:20%"><span>{{ data2 }}</span></th>
                                </tr>
                            </thead>
                            <tbody class="isprint-grid-modal-tbody" style="font-size:14px !important">
                                <tr style="left: 0px;height:50px"   v-for="articolo in paginatedListArticoli.arr" v-bind:key="articolo.ID_ARTICOLO">
                                    <td  style="width:60%">
                                        <kbd>{{ articolo.CODICE_ARTICOLO }}</kbd> {{ articolo.DESCRIZIONE }}
                                    </td>                                        
                                    <td  style="width:20%">
                                        {{ articolo.QTA_1 }}
                                    </td>
                                    <td  style="width:20%">
                                        {{ articolo.QTA_2 }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>   
                        <div class="row">
                            <div class="col-md-9">
                                <pagine v-model="searchArticoli.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListArticoli.count"  :numpagine="searchArticoli.PAGINATION.LENGTH"></pagine>
                            </div>
                            <div class="col-3" style="margin-top:20px">
                                
                            </div>   
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


</div>
</template>
<script>


import Upload from '@/components/utils/Upload.vue'
import Pagine from '@/components/utils/Pagine.vue'
import DatePicker from '@/components/utils/DatePicker.vue'


export default {
    name:"Magazzino",
    components:{
        Pagine,
        Upload,
        DatePicker
    },
    data:function(){
        return{
            filters:global.filters,
            args:{
                modalita:'NIC_uploadMagazzino'
            },
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
            },
            searchArticoli : {
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:10},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                CODICE_ARTICOLO:{TYPE:'TEXT',VALUE:''},
            },
            arrayFlussiCaricati : new Array(),
            moduloSelezionato : {},
            arrayArticoli:new Array(),
            data1:'',
            data2:'',
        }
    },
    methods: {
        caricaFlusso : function(){
            this.args.NOTE = "";
            this.args.DATA_MAGAZZINO = this.filters.getCurrentDate();
            var modal = $('#popUpFlussoMagazzino');
            modal.modal('show');  
        },
        successCaricamento : function(){
            var modal = $('#popUpFlussoMagazzino');
            modal.modal('hide'); 
            global.utils.ajax('/nic/magazzino/flussicaricati',{}, (response) => {
                this.arrayFlussiCaricati = response.data.reverse();
            });  
        },
        deleteFlusso : function(flusso){
            utils.alert.confirm("Sei sicuro di voler eliminare il flusso selezionato?",()=>{
               utils.ajax('nic/magazzino/flussocaricato/delete',{ID_FLUSSO_CARICATO:flusso.ID_FLUSSO_CARICATO}, (response) => {
                   if (response.esito == 0){
                       utils.alert.success(response.messaggio);
                       global.utils.ajax('/nic/magazzino/flussicaricati',{}, (response) => {
                            this.arrayFlussiCaricati = response.data.reverse();
                        });       
                    }
               });
           },this.$root);
        },
        confrontaSelezionati : function(){
            var arr = utils.array.select(this.arrayFlussiCaricati,"SELEZIONATO",1);
            if (arr.length == 2){
                utils.ajax('nic/magazzino/flussicaricati/confronta',{ID_FLUSSO_CARICATO_1:arr[0].ID_FLUSSO_CARICATO,ID_FLUSSO_CARICATO_2:arr[1].ID_FLUSSO_CARICATO}, (response) => {
                    this.arrayArticoli = response.data.ARTICOLI;
                    this.data1 = response.data.DATA_1;
                    this.data2 = response.data.DATA_2;
                    var modal = $('#popUpArticoliMagazzino');
                    modal.modal('show'); 
                });
            }
            else{
                utils.alert.warning("Selezionare due caricamenti");
            }
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayFlussiCaricati,this.search);
            return arr;
        },
        paginatedListArticoli(){
            var arr = utils.inGrid(this.arrayArticoli,this.searchArticoli);
            return arr;
        },
    },
    created : function(){
        global.utils.ajax('/nic/magazzino/flussicaricati',{}, (response) => {
            this.arrayFlussiCaricati = response.data.reverse();
        });        
    },
}
</script>