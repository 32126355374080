<template>
    <div class="row" style="border-bottom: 1px solid #ccc;padding-bottom: 50px;margin-top: 20px;">  
        <div class="col-4">
          <div class="card shadow"   style="margin-top: 10px;height:108px">
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                          <div class="font-weight-bold text-ecomm h3 mb-1">Nic stats versione {{$root.VERSIONE}}</div>
                          <div class="h6 mb-0  text-gray-800"><strong>Utente</strong> {{$root.utente.persona.COGNOME}} {{$root.utente.persona.NOME}}</div> 
                      </div> 
                      <div class="col-auto"><i class="fal fa-user fa-fw fa-4x" style="color:#0679ab;"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        <div class="col-4">
            <div class="card shadow"  style="margin-top: 10px;height:108px">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold text-ecomm h3 mb-1">Ente</div>
                            <div class="h6 mb-0  text-gray-800">Nic Srl </div> 
                        </div> 
                        <div class="col-auto"><i class="fal fa-university fa-fw fa-4x" style="color:#0679ab;"></i>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4 ">
          <div class="card shadow lift-panel"   style="margin-top: 10px;height:108px" >
              <div class="card-body">
                  <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                            <div class="font-weight-bold text-ecomm h3 mb-1">Data ultimo ordine caricato</div>
                            <div class="h6 mb-0  text-gray-800" >
                                <strong>{{ filters.formatDate(dataUltimoOrdine) }}</strong>
                            </div> 
                           
                      </div> 
                      <div class="col-auto"><i class="fal fa-calendar-alt fa-fw fa-4x" style="color:#0679ab;"></i>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
    <div class="row" style="margin-top:30px">
        <div class="col-3" style="height: 140px;"  v-if="hasFunzionalita(2)">
            <div class="card shadow lift-panel " v-on:click="openModulo('Flussi')" style="margin-top:20px;background:#1cc88a;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff">Flussi</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Visualizza lo storico dei flussi caricati</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-upload fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>


        
        <div class="col-3" style="height: 140px;"  v-if="hasFunzionalita(2)">
            <div class="card shadow lift-panel "    v-on:click="openModulo('Magazzino')" style="margin-top:20px;background:#cddc39;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff">Magazzino</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Confronta lo stato del magazzino</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fad fa-warehouse-alt fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>


        <div class="col-3" style="height: 140px;"  v-if="hasFunzionalita(5)">
            <div class="card shadow lift-panel "    v-on:click="openModulo('Ordini')" style="margin-top:20px;background:#f4b30d;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff">Ordini</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Visualizza gli ordini</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-shopping-cart fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
        <div class="col-3" style="height: 140px;" v-if="hasFunzionalita(6)">
            <div class="card shadow lift-panel "    v-on:click="openModulo('CentriRicavo')" style="margin-top:20px;background: #f04d91 !important;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff!important">Centri di ricavo</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Visualizza e gestisci i centri di ricavo</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-euro-sign fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>

        <div class="col-3" style="height: 140px;"  v-if="hasFunzionalita(4)">
            <div class="card shadow lift-panel "  v-on:click="openModulo('Statistiche')" style="margin-top:20px;background: #ed5949 !important;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff!important">Statistiche</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Grafici statistiche</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-chart-line fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>

        <div class="col-3" style="height: 140px;" v-if="hasFunzionalita(7)" >
            <div class="card shadow lift-panel "   v-on:click="openModulo('Articoli')" style="margin-top:20px;background: #4e73df !important;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff!important">Articoli</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Grafici articoli</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal  fa-box-alt fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>

        <div class="col-3" style="height: 140px;"  v-if="hasFunzionalita(3)" >
            <div class="card shadow lift-panel "  v-on:click="openModulo('Utenti')" style="margin-top:20px;background: #69d0ff !important;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff !important">Utenti</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Visualizza e gestisci gli utenti</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-sign-in fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>

        <div class="col-3" style="height: 140px;"  v-if="hasFunzionalita(8)" >
            <div class="card shadow lift-panel "  v-on:click="openModulo('Agenti')" style="margin-top:20px;background: #8227c8 !important;">
                <div class="card-body" >
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div class="font-weight-bold mb-1 h4" style="color:#fff !important">Agenti</div>  
                            <div class="h6 mb-0  " style="color:#fff" >Visualizza e gestisci gli utenti</div> 
                        </div>
                        <div class="col-auto">
                            <i class="fal fa-truck fa-4x" style="color:#fff"></i>
                        </div>
                    </div>
                </div>
            </div>        
        </div>

        
    </div>

<!--
    <input type="text" class="form-control" v-model="tabella"><button class="btn btn-info" v-on:click="creaTabella()">Crea tabella</button>
-->
</template>
<script>


export default {
    name:"Dashboard",
    components: {
      
    },
    data:function(){
      return{
        utente:{},
        tabella:"",
        dataUltimoOrdine:"",
        filters:global.filters
      }
    },
    methods:{
        creaTabella:function(){
            utils.ajax('nic/generaclasse',{tabella:this.tabella}, (response) => {
            
            });
        },
        openModulo : function(modulo){
            global.router.push(modulo);
        },
        hasFunzionalita : function(id){
            for (var i = 0 ; i < this.$root.utente.funzionalita.length ; i++){
                if (this.$root.utente.funzionalita[i].ID_FUNZIONALITA == id){
                    return true;
                }
            }
            return false;
        }
    },
    computed:{
        
    },
    created: function () {
        global.utils.ajax('core/home',{}, (response) => {
            this.dataUltimoOrdine = response.data.DATA_ULTIMO_ORDINE;
            this.$root.arrayStati = response.data.NAZIONI;
        });
    },
}
</script>