<template>
    <div>
       <div class="kt-portlet__head">
           <form class="isprint-filter" autocomplete="off">                
               <div class="form-group">
                   <label>Descrizione</label><br/>
                   <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Descrizione" type="text" style="width:150px" v-model="search.DESCRIZIONE.VALUE">
               </div>
               <button class="btn btn-success float-right btn-sm" v-on:click="nuovoCentroRicavo()" style="margin-top:26px"><i class="fal fa-plus"></i> Nuovo centro di ricavo</button>
           </form>
       </div>
       <div class="isprint-grid-container" >    
           <table class="isprint-grid shadow" >
               <thead class="isprint-grid-thead" >
               <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                   <tr style="left: 0px;background:#f04d91;">
                       <th  style="width:55%"><span>Descrizione</span></th>
                       <th  style="width:30%"><span>Incassi anno corrente</span></th>
                       <th  style="width:15%"><span >Azioni</span></th>
                   </tr>
               </thead>
               <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                   <tr style="left: 0px;height:50px"  v-for="centroRicavo in paginatedList.arr" v-bind:key="centroRicavo.ID_CENTRO_RICAVO">
                       <td  style="width:55%">
                         <div><strong>{{centroRicavo.DESCRIZIONE}}</strong></div>
                       </td>
                       <td  style="width:30%,text-align:right">
                           {{filters.formattaImporto(centroRicavo.IMPORTO)}}
                       </td>
                       <td  style="width:15%">
                           <button class="btn btn-danger float-right btn-sm" v-on:click="deleteCentroRicavo(centroRicavo)"><i class="fal fa-trash-alt"></i></button>
                           <button class="btn btn-warning float-right btn-sm" v-on:click="modificaCentroRicavo(centroRicavo)"><i class="fal fa-pencil"></i></button>
                       </td>
                   </tr>
               </tbody>
           </table>
           <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
       </div>



       <div class="modal fade" id="popUpCentroRicavo" data-backdrop="true" role="dialog"  aria-modal="true" >
           <div class="modal-dialog" style="min-width:800px;"  >
               <div class="modal-content" >
                   <div class="modal-header modal-header-warning" style="background:#f04d91;">
                       <h4 style="color:#fff" class="modal-title"><i class="fal  fa-calculator-alt fa-fw"></i> Centro di Ricavo</h4>
                       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span>&times;</span>
                       </button>
                   </div>
                   <div class="modal-body">
                       <form autocomplete="off">
                           <div class="row">
                               <div class="form-group col-12" >
                                   <label>Descrizione</label>
                                   <input type="text" class="form-control"  v-on:keyup.enter="salvaCentroRicavo()" v-model="centroRicavoSelezionato.DESCRIZIONE" placeholer="Descrizione">
                               </div>                    
                           </div>
                       </form>
                   </div>
                   <div class="modal-footer">
                       <button   data-dismiss="modal" class="btn btn-danger float-right"><i class="fal fa-times"></i> Annulla</button>
                       <button v-on:click="salvaCentroRicavo()" class="btn btn-success float-right"><i class="fal fa-check"></i> Salva</button>
                   </div>
               </div>
           </div>
       </div>
   </div>
</template>
<script>
import Pagine from '@/components/utils/Pagine.vue'

export default {
   name:"CentriRicavo",
   components:{
       Pagine
   },
   data:function(){
       return{
           filters:global.filters,
           search:{
               PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
               DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
               FLG_VISIBILE:{TYPE:'COMBO',VALUE:''}, 
           },
           arrayCentriRicavo : new Array(),
           centroRicavoSelezionato : {}
       }
   },
   methods: {
       getCentriRicavo : function(){
           utils.ajax('nic/centriricavo',{}, (response) => {
               this.arrayCentriRicavo = response.data;
           });
       },
       nuovoCentroRicavo : function(){
           this.centroRicavoSelezionato = {ID_CENTRO_RICAVO:0,FLG_VISIBILE:1};
           var modal = $('#popUpCentroRicavo');
           modal.modal('show');
       },
       modificaCentroRicavo : function(centroRicavo){
           this.centroRicavoSelezionato = utils.clone(centroRicavo);
           var modal = $('#popUpCentroRicavo');
           modal.modal('show');
       },
       deleteCentroRicavo : function(centroRicavo){
           utils.alert.confirm("Sei sicuro di voler eliminare il centro di ricavo <strong>"+centroRicavo.DESCRIZIONE+"?</strong>",()=>{
               utils.ajax('nic/centroricavo/delete',{ID_CENTRO_RICAVO:centroRicavo.ID_CENTRO_RICAVO}, (response) => {
                   if (response.esito == 0){
                       utils.alert.success(response.messaggio);
                       this.getCentriRicavo();
                   }
               });
           },this.$root);
       },
       salvaCentroRicavo : function(){
           var modal = $('#popUpCentroRicavo');
           modal.modal('hide');
           var api = this.centroRicavoSelezionato.ID_CENTRO_RICAVO > 0 ? 'nic/centroricavo/update' : 'nic/centroricavo/insert';
           utils.ajax(api,this.centroRicavoSelezionato, (response) => {
               if (response.esito == 0){
                   utils.alert.success(response.messaggio);
                   this.getCentriRicavo();
               }
           });  
       },
   },
   computed : {
       paginatedList(){
           var arr = utils.inGrid(this.arrayCentriRicavo,this.search);
           return arr;
       },
   },
   created : function(){
       this.getCentriRicavo();
   },
}
</script>