<template>
    <div>
    <div class="kt-portlet__head">
        <form class="isprint-filter" autocomplete="off">                
            <div class="form-group">
                <label>Cod. ordine</label><br/>
                <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Cod. ordine" type="text" style="width:100px" v-model="search.CODICE_ORDINE.VALUE">
            </div>
            <div class="form-group">
                <label>Data da</label><br/>
                <DatePicker autocomplete="off" :dim="'sm'" type="text" style="width:110px" v-model="search.DATA_ORDINE.VALUE_FROM"></DatePicker>
            </div>
            <div class="form-group">
                <label>Data a</label><br/>
                <DatePicker autocomplete="off" :dim="'sm'" type="text" style="width:110px" v-model="search.DATA_ORDINE.VALUE_TO"></DatePicker>
            </div>
            <div class="form-group ">
                <label>Cliente</label><br/>
                <Select2  v-bind:options="arrayClienti" v-model="search.FK_ID_CLIENTE.VALUE" :w="189"   ></Select2>
            </div>
            <div class="form-group">
                <label>Agente</label><br/>
                <Select2  v-bind:options="arrayAgenti" v-model="search.FK_ID_AGENTE.VALUE" :w="189" ></Select2>
            </div>  
            <div class="form-group float-right">
                <label>Anno</label><br/>
                <select class="form-control form-control-sm" v-model="annoSelezionato" @change="getOrdini()">
                    <option v-for="anno in arrayAnni" v-bind:key="anno.ANNO" v-bind:value="anno.ANNO" >{{ anno.ANNO }}</option>
                </select>
            </div>
        </form>
    </div>
    <div class="isprint-grid-container" >    
        <table class="isprint-grid shadow" >
            <thead class="isprint-grid-thead" >
                <tr style="left: 0px;background:#f4b30d;color:#fff">
                    <th  style="width:6%"><span>Codice</span></th>
                    <th  style="width:6%"><span>Data</span></th>
                    <th  style="width:38%"><span >Cliente</span></th>
                    <th  style="width:10%"><span >Agente</span></th>
                    <th  style="width:10%"><span >Importo</span></th>
                    <th  style="width:20%"><span >Articoli</span></th>
                </tr>
            </thead>
            <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                <tr style="left: 0px;height:50px" v-bind:key="ordine.ID_ORDINE"  v-for="ordine in paginatedList.arr">
                    <td  style="width:6%">
                        <div><kbd>{{ordine.CODICE_ORDINE}}</kbd></div>
                    </td>
                    <td  style="width:6%">
                       {{ filters.formatDate(ordine.DATA_ORDINE) }}
                    </td>
                    <td  style="width:38%">
                       <strong><i class="fal fa-user"></i> {{ordine.CODICE_CLIENTE}}</strong> - {{  ordine.DENOMINAZIONE }}
                    </td>
                    <td  style="width:10%">
                        <span class="badge badge-info">{{ordine.CODICE_AGENTE}}</span>
                    </td>
                    <td  style="width:10%;text-align:right">
                        € {{filters.formattaImporto(ordine.IMPORTO)}}
                    </td>
                    <td  style="width:20%">
                       <button class="btn btn-primary btn-grid float-right" v-on:click="openOrdine(ordine)">Mostra <strong>{{ ordine.TOT_ARTICOLI }}</strong> articoli</button>
                    </td>
                    
                </tr>
            </tbody>
        </table>

        <div class="row">
            <div class="col-9">
                <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
            </div>
            <div class="col-3" style="margin-top:20px" v-show="this.$root.isAdmin == true">
                <ul class="pagination float-right">
                    <li class="kt-pagination__link--last page-item"><span tabindex="0" class="page-link" style="font-weight: bold;background:#1cc88a;color:#fff">€ {{filters.formattaImporto(getTotale)}}</span></li>
                </ul>  
            </div>                
        </div>        
    </div>



    <div class="modal fade" id="popUpOrdine" data-backdrop="true" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" style="min-width:804px;"  >
            <div class="modal-content" >
                <div class="modal-header modal-header-warning" style="background:#717384;">
                    <h4 style="color:#fff" class="modal-title"><i class="fal fa-euro-sign fa-fw"></i> Ordine {{ ordineSelezionato.CODICE_ORDINE }} - {{ ordineSelezionato.DENOMINAZIONE }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="list-group">
                        <li class="list-group-item" v-for="articolo in arrayArticoliOrdineSelezionato" v-bind:key="articolo.ID_QUOTA">
                            <div class="col-3" style="text-align: center;">
                                <kbd>{{ articolo.CODICE_ARTICOLO }}</kbd> 
                            </div>
                            <div class="col-6">
                                {{ articolo.DESCRIZIONE }}
                            </div>                            
                            <div class="col-3" style="text-align: right;">
                                € {{ filters.formattaImporto(articolo.IMPORTO) }}
                            </div>                            
                        </li>
                        <li class="list-group-item" >
                            <div class="col-3" style="text-align: center;">
                                <kbd style="font-weight: bold;font-size:20px">TOTALE</kbd>
                            </div>
                            <div class="col-6" style="text-align: center;height: 1px;">
                                
                            </div>
                            <div class="col-3" style="text-align: right;">
                                <span class="badge badge-success" style="font-weight: bold;font-size:20px">€ {{ filters.formattaImporto(ordineSelezionato.IMPORTO) }}</span>
                            </div>                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>


import Pagine from '@/components/utils/Pagine.vue'
import DatePicker from '@/components/utils/DatePicker.vue'
import Select2 from '@/components/utils/Select2.vue'



export default {
    name:"Ordini",
    components:{
        Pagine,
        DatePicker,
        Select2
    },
    data:function(){
        return{
            filters:global.filters,
            args:{
                modalita:'NIC_uploadFlusso'
            },
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                CODICE_ORDINE:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_CLIENTE:{TYPE:'COMBO',VALUE:""}, 
                FK_ID_AGENTE:{TYPE:'COMBO',VALUE:""}, 
                DATA_ORDINE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},
            },
            arrayOrdini : new Array(),
            arrayAnni : new Array({ANNO:new Date().getFullYear()},{ANNO:new Date().getFullYear()-1},{ANNO:new Date().getFullYear()-2}),
            annoSelezionato : new Date().getFullYear(),
            arrayArticoliOrdineSelezionato:new Array(),
            ordineSelezionato : {},
            arrayAgenti:new Array(),
            arrayClienti:new Array()
        }
    },
    methods: {
        getOrdini : function(){
            global.utils.ajax('/nic/ordini',{ANNO:this.annoSelezionato}, (response) => {
                this.arrayOrdini = response.data;
            });            
        },
        openOrdine : function(ordine){
            this.ordineSelezionato = utils.clone(ordine);
            global.utils.ajax('/nic/ordine',{ID_ORDINE:ordine.ID_ORDINE}, (response) => {
                this.arrayArticoliOrdineSelezionato = response.data.articoli;
                var modal = $('#popUpOrdine');
                modal.modal('show'); 
            });                        
        }
    },
    watch:{
        'search.FK_ID_CLIENTE.VALUE' : function(){
            if (this.search.FK_ID_CLIENTE.VALUE == null || this.search.FK_ID_CLIENTE.VALUE == 0){
                this.search.FK_ID_CLIENTE.VALUE = "";
            }
        },
        'search.FK_ID_AGENTE.VALUE' : function(){
            if (this.search.FK_ID_AGENTE.VALUE == null || this.search.FK_ID_AGENTE.VALUE == 0){
                this.search.FK_ID_AGENTE.VALUE = "";
            }
        },
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayOrdini,this.search);
            return arr;
        },
        getTotale : function(){
            var totale = 0;
            var arr = utils.filter(this.arrayOrdini,this.search);
            for (var i = 0 ; i < arr.length ; i++){
                totale += parseFloat(arr[i].IMPORTO);      
            }
            return totale;
        }
    },
    created : function(){
        utils.ajax('nic/statistiche/startup',{}, (response) => {
            var arrayClienti = utils.toSelect2(response.data.CLIENTI,"ID_CLIENTE","DENOMINAZIONE");
            arrayClienti.splice(0,0,{id:0,text:'Tutti i clienti'});
            this.arrayClienti = utils.clone(arrayClienti);

            var arrayAgenti = utils.toSelect2(response.data.AGENTI,"ID_AGENTE","DESCRIZIONE");
            arrayAgenti.splice(0,0,{id:0,text:'Tutti gli agenti'});
            this.arrayAgenti = utils.clone(arrayAgenti);

            this.getOrdini();

        });
    },
}
</script>