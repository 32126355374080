<template>
    <div>
        <div class="kt-portlet__head">
            <form class="isprint-filter" autocomplete="off">                
                <div class="form-group">
                    <label>Codice agente</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Codice Agente" type="text" style="width:150px" v-model="search.CODICE_AGENTE.VALUE">
                </div>
                <div class="form-group">
                    <label>Descrizione</label><br/>
                    <input class="form-control form-control-sm"  autocomplete="off" name="adasd" placeholder="Descrizione" type="text" style="width:150px" v-model="search.DESCRIZIONE.VALUE">
                </div> 
                <div class="form-group">
                    <label>Stato</label><br/>
                    <select class="form-control form-control-sm" v-model="search.FK_ID_NAZIONALITA.VALUE">
                        <option value="">Tutti</option>
                        <option v-for="stato in $root.arrayStati" v-bind:value="stato.ID_STATO" v-bind:key="stato.ID_STATO">{{ stato.NOME }}</option>
                    </select>
                </div>             
            </form>
        </div>
        <div class="isprint-grid-container" >    
            <table class="isprint-grid shadow" >
                <thead class="isprint-grid-thead" >
                <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                    <tr style="left: 0px;background:#4e73df;color:#fff">
                        <th  style="width:15%"><span>Codice agente</span></th>
                        <th  style="width:70%"><span>Descrizione</span></th>                        
                        <th  style="width:15%"><span >Azioni</span></th>
                    </tr>
                </thead>
                <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                    <tr style="left: 0px;height:50px"  v-for="agente in paginatedList.arr" v-bind:key="agente.ID_AGENTE">
                        <td  style="width:15%">
                            <kbd style="font-size:16px">{{ agente.CODICE_AGENTE }}</kbd>
                        </td>
                        <td  style="width:70%">
                            <div style="float:left"><strong>{{agente.DESCRIZIONE}}</strong></div>
                        </td>                                             
                        <td  style="width:15%">
                            <button class="btn btn-warning float-right btn-sm" v-on:click="modificaAgente(agente)">Modifica</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
        </div>


        <div class="modal fade" id="popUpAgente" data-backdrop="true" role="dialog"  aria-modal="true" >
            <div class="modal-dialog" style="min-width:800px;"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-primary">
                        <h4 style="color:#fff" class="modal-title"><i class="fal fa-shopping-cart fa-fw"></i> Agente</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="height:280px">
                        <div class="kt-portlet__head" >
                            <div class="form-group">
                                <label>Codice</label><br/>
                                <input class="form-control "  autocomplete="off" name="adasd" placeholder="Descrizione" type="text" v-model="agenteSelezionato.CODICE_AGENTE">
                            </div>
                            <div class="form-group">
                                <label>Descrizione</label><br/>
                                <input class="form-control"  autocomplete="off" name="adasd" placeholder="Descrizione" type="text" v-model="agenteSelezionato.DESCRIZIONE">
                            </div>
                            <div class="form-group">
                                <label>Stato</label><br/>
                                <select class="form-control" v-model="agenteSelezionato.FK_ID_NAZIONALITA">
                                    <option v-for="stato in $root.arrayStati" v-bind:value="stato.ID_STATO" v-bind:key="stato.ID_STATO">{{ stato.NOME }}</option>
                                </select>
                            </div>
                        </div>  
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success float-right" v-on:click="salvaAgente()"><i class="fa fa-check"></i> Salva</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

import Pagine from '@/components/utils/Pagine.vue'


export default {
        name:"Agenti",
        components:{
            Pagine,
        },
        data:function(){
        return{
            filters:global.filters,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                CODICE_AGENTE:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_NAZIONALITA:{TYPE:'COMBO',VALUE:''}, 
            },
            searchCr:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:18},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            arrayAgenti : new Array(),
            arrayCentriRicavo : new Array(),
            agenteSelezionato : {centriRicavo:new Array()}
        }
    },
    methods: {
        getAgenti : function(){
            utils.ajax('/nic/agenti',{}, (response) => {
                this.arrayAgenti = response.data;
            });
        },      
        modificaAgente : function(agente){
            this.agenteSelezionato = utils.clone(agente);
            var modal = $('#popUpAgente');
            modal.modal('show');
        },
        salvaAgente : function(){
            utils.ajax('/nic/agente/update',this.agenteSelezionato, (response) => {
                if (response.esito == 0){
                    var modal = $('#popUpAgente');
                    modal.modal('hide');
                    this.getAgenti();
                    utils.alert.success(response.messaggio);
                }
            });
        }
    },
    computed : {
        paginatedList(){
            var arr = utils.inGrid(this.arrayAgenti,this.search);
            return arr;
        },
        paginatedListCr(){
            if (this.arrayCentriRicavo == undefined || this.arrayCentriRicavo == null)
                return new Array();
            var arr = global.utils.inGrid(this.arrayCentriRicavo,this.searchCr);
            return arr;
        },
    },
    created : function(){
        this.getAgenti();  
    },
}
</script>