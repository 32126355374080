import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Flussi from '../views/Flussi.vue'
import Ordini from '../views/Ordini.vue'
import Magazzino from '../views/Magazzino.vue'
import CentriRicavo from '../views/CentriRicavo.vue'
import Articoli from '../views/Articoli.vue'
import Statistiche from '../views/Statistiche.vue'
import Agenti from '../views/Agenti.vue'
import Utenti from '../components/common/Utenti.vue'




const routes = [
    {path: '/login',  name: 'Login',    component: Login},
    {path: '/dashboard',  name: 'Dashboard',    component: Dashboard},
    {path: '/flussi',  name: 'Flussi',    component: Flussi},
    {path: '/ordini',  name: 'Ordini',    component: Ordini},
    {path: '/CentriRicavo',  name: 'CentriRicavo',    component: CentriRicavo},
    {path: '/Articoli',  name: 'Articoli',    component: Articoli},
    {path: '/Statistiche',  name: 'Statistiche',    component: Statistiche},
    {path: '/Utenti',  name: 'Utenti',    component: Utenti},
    {path: '/Agenti',  name: 'Agenti',    component: Agenti},
    {path: '/Magazzino',  name: 'Magazzino',    component: Magazzino},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
