<template>
    <div>
        <ul class="nav nav-tabs" id="tabs" role="tablist" style="margin-top:25px">
            <li class="nav-item">
                <a class="nav-link isprint-tab active statistiche" data-toggle="tab" id="linkAgenti" data-tab="statsAgenti" href="#statsAgenti"  role="tab" aria-controls="statsAgenti" aria-selected="false">
                    <i class="fal fa-truck"></i> Agenti
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link isprint-tab  statistiche"  data-toggle="tab" id="linkPagamenti" data-tab="statspagamenti" href="#statspagamenti"  role="tab" aria-controls="statspagamenti" aria-selected="true">
                    <i class="fal fa-chart-bar"></i> Grafico agenti
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link isprint-tab statistiche" data-toggle="tab" id="linkArticoli" data-tab="statsArticoli" href="#statsArticoli"  role="tab" aria-controls="statsArticoli" aria-selected="false">
                    <i class="fal  fa-box-alt"></i> Articoli
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link isprint-tab statistiche" data-toggle="tab" id="linkArticoli" data-tab="statsStati" href="#statsStati"  role="tab" aria-controls="statsStati" aria-selected="false">
                    <i class="fal  fa-flag"></i> Stati
                </a>
            </li>

        </ul>
        <div class="tab-content card-tab" style="margin-top:-20px" >
            <div class="tab-pane active" id="statsAgenti" style="height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;height:100%">
                    <div class="card-header" style="height:70px">
                        <div class="row">
                            <div class="form-group ml-4 mt-3 float-left" >
                                <i class="fal fa-filter fa-2x"></i>
                            </div>  
                            <div class="form-group mt-1 mr-2 ml-4  float-left" >
                                <label>Data da</label>
                                <DatePicker placeholder="Data da" autocomplete="off" :dim="'sm'" type="text" style="width:150px" v-model="searchStatsAgenti.DATA_ORDINE.VALUE_FROM"></DatePicker>
                            </div>
                            <div class="form-group mt-1  mr-2  float-left">
                                <label>Data a</label>
                                <DatePicker placeholder="Data a" autocomplete="off" :dim="'sm'" type="text" style="width:150px" v-model="searchStatsAgenti.DATA_ORDINE.VALUE_TO"></DatePicker>
                            </div>
                            <div class="form-group mt-1 mr-2">
                                <label>Centri ricavo</label>
                                <Select2  v-bind:options="arrayCentriRicavo" v-model="searchStatsAgenti.FK_ID_CENTRO_RICAVO.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>
                            <div class="form-group mt-1 mr-2">
                                <label>Articoli</label>
                                <Select2  v-bind:options="arrayArticoli" v-model="searchStatsAgenti.FK_ID_ARTICOLO.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>
                           
                            
                            <div class="form-group mt-1 mr-2" style="border-left:1px solid #ccc;padding-left:20px">
                                <label>Agente</label>
                                <Select2  v-bind:options="arrayAgenti" v-model="searchStatsAgentiPaginazione.FK_ID_AGENTE.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>  

                            <div class="form-group mt-1 mr-2">
                                <label>Stato</label><br/>
                                <select class="form-control form-control-sm" v-model="searchStatsAgentiPaginazione.FK_ID_NAZIONALITA.VALUE">
                                    <option value="">Tutti</option>
                                    <option v-for="stato in $root.arrayStati" v-bind:value="stato.ID_STATO" v-bind:key="stato.ID_STATO">{{ stato.NOME }}</option>
                                </select>
                            </div> 
                            <!--                       
                            <div class="form-group mt-2 mr-2  float-left">
                                <button v-on:click="getStatisticheQuote()" class="btn btn-info btn-sm"><i class="fal fa-sync"></i> Aggiorna ricerca</button>
                            </div>-->
                             <div class="form-group mt-2 mr-2  float-right">
                                <button v-on:click="esportaAgente({ID_AGENTE:0,IMPORTO:0,IMPORTO_T1:0})" style="margin-top:22px" class="btn btn-success btn-sm float-right"><i class="fa-fw fal fa-file-excel"></i> Esporta</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" >
                        <div class="isprint-grid-container" >    
                            <table class="isprint-grid shadow" style="height:100% !important" >
                                <thead class="isprint-grid-thead" >
                                <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                                    <tr style="left: 0px;background:#ff5722"  >
                                        <th  style="width:50%"><span>Agente</span></th>
                                        <th  style="width:15%"><span>IMPORTO (T-1)</span></th>
                                        <th  style="width:15%"><span>IMPORTO (T)</span></th>
                                        <th  style="width:10%"><span>VAR %</span></th>
                                        <th  style="width:10%"><span>Azioni</span></th>
                                    </tr>
                                </thead>
                                <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                                    <template v-for="agente in paginatedListAgenti.arr" v-bind:key="agente.FK_ID_AGENTE">
                                        <tr  v-bind:style="getStyleAgente(agente)" >
                                            <td  style="width:50%">
                                                <span style="font-weight: bold;width:50px;" class="badge badge-primary">{{agente.CODICE_AGENTE}}</span> <strong>{{ agente.DESCRIZIONE }}</strong>
                                            </td>      
                                            <td  style="width:15%;text-align:right"><strong>€ {{filters.formattaImporto(agente.IMPORTO_T1)}}</strong></td>
                                            <td  style="width:15%;text-align:right"><strong>€ {{filters.formattaImporto(agente.IMPORTO)}}</strong></td>
                                            <td  style="width:10%;text-align:right"><span class="badge" style="font-size:20px" v-bind:class="{'badge-success':agente.VARIAZIONE_PERC > 0, 'badge-danger':agente.VARIAZIONE_PERC < 0,'badge-info':agente.VARIAZIONE_PERC == 0}">{{filters.formattaImporto(agente.VARIAZIONE_PERC)}}%</span></td>
                                            <td  style="width:10%;text-align:right">
                                                <button class="btn btn-success btn-sm float-right" v-on:click="esportaAgente(agente)"><i class="fal fa-file-excel"></i></button>
                                                <button class="btn btn-warning btn-sm float-right" v-on:click="openAgente(agente)"><i class="fal fa-folder"></i></button>
                                            </td>
                                        </tr>   
                                        <tr  v-for="cliente in agente.ARRAY_CLIENTI" v-bind:key="cliente.FK_ID_CLIENTE">
                                            <td  style="width:50%;">
                                                <span class="badge badge-info" style="margin-left:40px;width:80px">{{cliente.CODICE_CLIENTE}}</span> {{ cliente.DENOMINAZIONE }}
                                            </td>      
                                            <td  style="width:15%;text-align:right">€ {{filters.formattaImporto(cliente.IMPORTO_T1)}}</td>
                                            <td  style="width:15%;text-align:right">€ {{filters.formattaImporto(cliente.IMPORTO)}}</td>
                                            <td  style="width:10%;text-align:right"><span class="badge" style="font-size:20px" v-bind:class="{'badge-success':cliente.VARIAZIONE_PERC > 0, 'badge-danger':cliente.VARIAZIONE_PERC < 0,'badge-info':cliente.VARIAZIONE_PERC == 0}">{{filters.formattaImporto(cliente.VARIAZIONE_PERC)}}%</span></td>
                                            <td  style="width:10%;text-align:right">
                                                
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>  

                            <div class="row">
                                <div class="col-9">
                                    
                                    <Pagine v-model="searchStatsAgentiPaginazione.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListAgenti.count"  :numpagine="searchStatsAgentiPaginazione.PAGINATION.LENGTH"></Pagine>   
                                        
                                </div>
                                              
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="statspagamenti" style="min-height:600px;height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;min-height:600px;height:100%">
                    <div class="card-header" style="height:70px">
                        <div class="row">
                            <div class="form-group ml-4 mt-3" >
                                <i class="fal fa-filter fa-2x"></i>
                            </div>
                            <div class="form-group mr-2 ml-4 mt-1">
                                <label>Clienti</label>
                                <Select2  v-bind:options="arrayClienti" v-model="searchPagamenti.FK_ID_CLIENTE.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>
                            <div class="form-group mt-1 mr-2">
                                <label>Centri ricavo</label>
                                <Select2  v-bind:options="arrayCentriRicavo" v-model="searchPagamenti.FK_ID_CENTRO_RICAVO.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>
                            <div class="form-group mt-1 mr-2">
                                <label>Articoli</label>
                                <Select2  v-bind:options="arrayArticoli" v-model="searchPagamenti.FK_ID_ARTICOLO.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>
                            <div class="form-group mt-1 mr-2">
                                <label>Agenti</label>
                                <Select2  v-bind:options="arrayAgenti" v-model="searchPagamenti.FK_ID_AGENTE.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>
                        </div>
                    </div>
                    <div class="card-body grafico" id="chart1">
                    
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="statsArticoli" style="height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;height:100%">
                    <div class="card-header" style="height:70px">
                        <div class="row">
                            <div class="form-group ml-4 mt-3 float-left" >
                                <i class="fal fa-filter fa-2x"></i>
                            </div>  
                            <div class="form-group mt-1 mr-2 ml-4  float-left" >
                                <label>Data da</label>
                                <DatePicker placeholder="Data da" autocomplete="off" :dim="'sm'" type="text" style="width:150px" v-model="searchStatsArticoli.DATA_ORDINE.VALUE_FROM"></DatePicker>
                            </div>
                            <div class="form-group mt-1  mr-2  float-left">
                                <label>Data a</label>
                                <DatePicker placeholder="Data a" autocomplete="off" :dim="'sm'" type="text" style="width:150px" v-model="searchStatsArticoli.DATA_ORDINE.VALUE_TO"></DatePicker>
                            </div>
                            <div class="form-group mr-2 ml-2 mt-1">
                                <label>Clienti</label>
                                <Select2  v-bind:options="arrayClienti" v-model="searchStatsArticoli.FK_ID_CLIENTE.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>
                            <div class="form-group mt-1 mr-2">
                                <label>Centri ricavo</label>
                                <Select2  v-bind:options="arrayCentriRicavo" v-model="searchStatsArticoli.FK_ID_CENTRO_RICAVO.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>
                            <div class="form-group mt-1 mr-2">
                                <label>Articoli</label>
                                <Select2  v-bind:options="arrayArticoli" v-model="searchStatsArticoli.FK_ID_ARTICOLO.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>
                            <div class="form-group mt-1 mr-2">
                                <label>Agenti</label>
                                <Select2  v-bind:options="arrayAgenti" v-model="searchStatsArticoli.FK_ID_AGENTE.VALUE" :w="189"  @onSelectItem="getStatistichePagamenti()" @change="getStatistichePagamenti()" ></Select2>
                            </div>  
                            <!--                       
                            <div class="form-group mt-2 mr-2  float-left">
                                <button v-on:click="getStatisticheQuote()" class="btn btn-info btn-sm"><i class="fal fa-sync"></i> Aggiorna ricerca</button>
                            </div>-->
                             <div class="form-group mt-2 mr-2  float-right">
                                <button v-on:click="printStatisticheQuote()" style="margin-top:22px" class="btn btn-success btn-sm float-right"><i class="fa-fw fal fa-file-excel"></i> Esporta</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" >
                        <div class="isprint-grid-container" >    
                            <table class="isprint-grid shadow" style="height:100% !important" >
                                <thead class="isprint-grid-thead" >
                                <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                                    <tr style="left: 0px;background:#ff5722"  >
                                        <th  style="width:40%"><span>Articolo</span></th>
                                        <th  style="width:10%"><span>Quantità</span></th>
                                        <th  style="width:20%"><span>Cliente</span></th>
                                        <th  style="width:10%"><span>Ordine</span></th>
                                        <th  style="width:10%"><span>Data</span></th>
                                        <th  style="width:10%"><span>Importo</span></th>
                                    </tr>
                                </thead>
                                <tbody class="isprint-grid-tbody" style="font-size:14px !important">
                                    
                                    <tr dstyle="left: 0px;height:54px"  v-for="quota in paginatedListArticoli.arr" v-bind:key="quota.ID_QUOTA">
                                        <td  style="width:40%">
                                            <kbd>{{quota.CODICE_ARTICOLO}}</kbd> {{quota.DESCRIZIONE}}
                                        </td>
                                        
                                        <td  style="width:10%">{{quota.QUANTITA}}</td>
                                        <td  style="width:20%">{{quota.CODICE_CLIENTE}} - {{quota.DENOMINAZIONE}}</td>

                                        <td  style="width:10%">{{quota.CODICE_ORDINE}}</td>
                                        <td  style="width:10%">{{filters.formatDate(quota.DATA_ORDINE)}}</td>
                                        <td  style="width:10%;text-align:right">{{filters.formattaImporto(quota.IMPORTO)}}</td>
                                    </tr>
                                    
                                </tbody>
                            </table>  

                            <div class="row">
                                <div class="col-9">
                                    
                                    <Pagine v-model="searchStatsArticoliPaginazione.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListArticoli.count"  :numpagine="searchStatsArticoliPaginazione.PAGINATION.LENGTH"></Pagine>   
                                        
                                </div>
                                <div class="col-3" style="margin-top:20px">
                                    <ul class="pagination float-right">
                                        <li class="kt-pagination__link--last page-item"><span tabindex="0" class="page-link" style="font-weight: bold;background:#1cc88a;color:#fff">€ {{filters.formattaImporto(getTotale) }}</span></li>
                                    </ul>  
                                </div>                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="statsStati" style="height:100%" role="tabpanel">
                <div class="card shadow" style="margin-top:20px;height:100%">
                    <div class="card-header" style="height:70px">
                        <div class="row">
                            <div class="form-group ml-4 mt-3 float-left" >
                                <i class="fal fa-filter fa-2x"></i>
                            </div>  
                            <div class="form-group mt-1 mr-2 ml-4  float-left" >
                                <label>Data da</label>
                                <DatePicker placeholder="Data da" autocomplete="off" :dim="'sm'" type="text" style="width:150px" v-model="searchStatsStati.DATA_ORDINE.VALUE_FROM"></DatePicker>
                            </div>
                            <div class="form-group mt-1  mr-2  float-left">
                                <label>Data a</label>
                                <DatePicker placeholder="Data a" autocomplete="off" :dim="'sm'" type="text" style="width:150px" v-model="searchStatsStati.DATA_ORDINE.VALUE_TO"></DatePicker>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" >
                        <div class="isprint-grid-container" >    
                            <table class="isprint-grid shadow" style="height:100% !important" >
                                <thead class="isprint-grid-thead" >
                                <!--<isort v-bind:ordinamento.sync="ordinamento" :label="'Cognome e nome'" :campo="'COGNOME'" ></isort>-->
                                    <tr style="left: 0px;background:#ff5722"  >
                                        <th  style="width:80%"><span>Stato</span></th>
                                        <th  style="width:20%"><span>Fatturato</span></th>
                                    </tr>
                                </thead>
                                <tbody class="isprint-grid-tbody" style="font-size:14px !important">                                    
                                    <tr dstyle="left: 0px;height:54px"  v-for="stato in paginatedListStati.arr" v-bind:key="stato.ID_STATO">
                                        <td  style="width:80%">
                                            {{ stato.NOME }}
                                        </td>
                                        <td  style="width:20%;text-align:right">{{filters.formattaImporto(stato.IMPORTO)}}</td>
                                    </tr>                                    
                                </tbody>
                            </table>  

                            <div class="row">
                                <div class="col-9">                                    
                                    <Pagine v-model="searchStatsStatiPaginazione.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedListStati.count"  :numpagine="searchStatsStatiPaginazione.PAGINATION.LENGTH"></Pagine>                                           
                                </div>
                                <div class="col-3" style="margin-top:20px">
                                    <ul class="pagination float-right">
                                        <li class="kt-pagination__link--last page-item"><span tabindex="0" class="page-link" style="font-weight: bold;background:#1cc88a;color:#fff">€ {{filters.formattaImporto(getTotale) }}</span></li>
                                    </ul>  
                                </div>                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import Pagine from '@/components/utils/Pagine.vue'
import Select2 from '@/components/utils/Select2.vue'
import DatePicker from '@/components/utils/DatePicker.vue'


export default {
    name:"Statistiche",
    components:{
        Select2,
        DatePicker,
        Pagine
    },
    props:[],
    data:function(){
        return{
            filters:global.filters,
            searchPagamenti:{
                FK_ID_CLIENTE:{TYPE:'COMBO',VALUE:0},
                FK_ID_ARTICOLO:{TYPE:'COMBO',VALUE:0},
                FK_ID_AGENTE:{TYPE:'COMBO',VALUE:0},
                FK_ID_CENTRO_RICAVO:{TYPE:'COMBO',VALUE:0},
            },
            searchStatsArticoli:{
                DATA_ORDINE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},
                FK_ID_CLIENTE:{TYPE:'COMBO',VALUE:0},
                FK_ID_ARTICOLO:{TYPE:'COMBO',VALUE:0},
                FK_ID_AGENTE:{TYPE:'COMBO',VALUE:0},
                FK_ID_CENTRO_RICAVO:{TYPE:'COMBO',VALUE:0}
            },
            searchStatsAgenti:{
                //MESE_DA:{TYPE:'COMBO',VALUE:''},
                //ANNO_DA:{TYPE:'COMBO',VALUE:new Date().getFullYear()},
                //MESE_A:{TYPE:'COMBO',VALUE:''},
                //ANNO_A:{TYPE:'COMBO',VALUE:new Date().getFullYear()},
                DATA_ORDINE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},
                FK_ID_CLIENTE:{TYPE:'COMBO',VALUE:0},
                FK_ID_ARTICOLO:{TYPE:'COMBO',VALUE:0},
                FK_ID_CENTRO_RICAVO:{TYPE:'COMBO',VALUE:0}
            },
            searchStatsArticoliPaginazione:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
            },
            searchStatsAgentiPaginazione:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
                FK_ID_AGENTE:{TYPE:'COMBO',VALUE:''},
                FK_ID_NAZIONALITA:{TYPE:'COMBO',VALUE:''},
            },
            searchStatsStatiPaginazione:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:50},
            },
            searchStatsStati:{
                DATA_ORDINE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},
            },
           
            arrayListe : new Array(),
            arrayLivelli : new Array(),
            arrayProdotti : new Array(),
            arrayAnni : new Array(),
            arrayStatisticheSoci : new Array(),
            arrayCentriCosto : new Array(),
            arrayStaff : new Array(),
            arrayStatisticheQuote : new Array(),
            socioSelezionato:{SOCIO:{}},

            ////////////////////////////////
            arrayStatisticheArticoli : new Array(),
            arrayStatisticheAgenti : new Array(),
            arrayStatisticheStati : new Array(),
            arrayArticoli:new Array(),
            arrayCentriRicavo:new Array(),
            arrayClienti:new Array(),
            arrayAgenti:new Array(),
            arrayMesi : new Array(),
            arrayAnniAgenti : new Array({ANNO:new Date().getFullYear()},{ANNO:new Date().getFullYear()-1},{ANNO:new Date().getFullYear()-2})
        }
    },
    methods: {
        getStatistichePagamenti : function(){
            
            $('.grafico').removeAttr("_echarts_instance_");
            $('.grafico').empty();
            utils.ajax('nic/statistiche/pagamenti',{FK_ID_CENTRO_RICAVO:this.searchPagamenti.FK_ID_CENTRO_RICAVO.VALUE,
                                                    FK_ID_ARTICOLO:this.searchPagamenti.FK_ID_ARTICOLO.VALUE,
                                                    FK_ID_CLIENTE:this.searchPagamenti.FK_ID_CLIENTE.VALUE,
                                                    FK_ID_AGENTE:this.searchPagamenti.FK_ID_AGENTE.VALUE}, (response) => {
                var option= {};
                //var chart1 = echarts.init(document.getElementById('chart1'));
                let chart1 = echarts.getInstanceByDom (document.getElementById ('chart1')); // get some words DOM node has echarts instance.
                if (chart1 == null) {// If not, it is initialized.
                    chart1 = echarts.init(document.getElementById('chart1'));
                }

                var arrayDistinctMesi = utils.array.distinct(response.data,"MESE","MESE");
                var arrayDistinctAnni = utils.array.distinct(response.data,"ANNO","ANNO");
                var series = new Array();
                var arrayAnni = [];
                for (var i = 0 ; i < arrayDistinctAnni.length ; i++){
                    arrayAnni.push(arrayDistinctAnni[i].ANNO.toString());
                }
                var arrayMesi = [];
                for (var i = 0 ; i < arrayDistinctMesi.length ; i++){
                    arrayMesi.push(utils.array.select(utils.arrayMesi,"ID_MESE",arrayDistinctMesi[i].MESE,1)[0].DESCRIZIONE);
                }
                for (var i = 0 ; i < arrayDistinctAnni.length ; i++){
                    var serie = {
                        name: arrayDistinctAnni[i].ANNO.toString(),
                        type: 'bar',
                        barGap: 0.2,
                        data: []
                    };
                    for (var j = 0 ; j < arrayDistinctMesi.length ; j++){
                        var trovato = 0;
                        for (var k = 0 ; k < response.data.length ; k++ ){
                            if (response.data[k].MESE == arrayDistinctMesi[j].MESE && response.data[k].ANNO == arrayDistinctAnni[i].ANNO){
                            trovato = 1;
                            serie.data.push(parseFloat(response.data[k].IMPORTO));
                            break;
                            }
                        }
                        if (trovato == 0){
                            serie.data.push(0);
                        }
                    }
                    series.push(serie);
                }
                option = {
                    tooltip: {
                        trigger: 'item',
                        formatter : (params) => {
                            return "<strong>" + params.name + " " +  params.seriesName + "</strong><br/>€ "+ utils.formatNumber(params.data) ;
                        }
                    },
                    legend: {
                        data: arrayAnni
                    },
                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {show: true},
                            magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
                            restore: {show: true},
                            saveAsImage: {show: true}
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: arrayMesi
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel : {
                            formatter : (param) => {
                                return utils.formatNumberInt(param);//"<strong>" + params.name + " " +  params.seriesName + "</strong><br/>"+ params.data ;
                            }
                            },
                        }
                    ],
                    series: series
                };    
                chart1.setOption(option);
                         
            });
        } ,
        getStatisticheArticoli : function(){
            utils.ajax('nic/statistiche/articoli',{FK_ID_CENTRO_RICAVO:this.searchStatsArticoli.FK_ID_CENTRO_RICAVO.VALUE,
                                                    FK_ID_ARTICOLO:this.searchStatsArticoli.FK_ID_ARTICOLO.VALUE,
                                                    FK_ID_CLIENTE:this.searchStatsArticoli.FK_ID_CLIENTE.VALUE,
                                                    FK_ID_AGENTE:this.searchStatsArticoli.FK_ID_AGENTE.VALUE,
                                                    DATA_DA:this.searchStatsArticoli.DATA_ORDINE.VALUE_FROM,
                                                    DATA_A:this.searchStatsArticoli.DATA_ORDINE.VALUE_TO}, (response) => {
                this.arrayStatisticheArticoli = response.data;
            }); 
        },
        getStatisticheAgenti : function(){
            utils.ajax('nic/statistiche/agenti',{FK_ID_CENTRO_RICAVO:this.searchStatsAgenti.FK_ID_CENTRO_RICAVO.VALUE,
                                                    FK_ID_ARTICOLO:this.searchStatsAgenti.FK_ID_ARTICOLO.VALUE,
                                                    DATA_DA:this.searchStatsAgenti.DATA_ORDINE.VALUE_FROM,
                                                    DATA_A:this.searchStatsAgenti.DATA_ORDINE.VALUE_TO
                                                },
            (response) => {
                this.arrayStatisticheAgenti = response.data;
            }); 
        },
        getStatisticheStati : function(){
            utils.ajax('nic/statistiche/stati',{DATA_DA:this.searchStatsStati.DATA_ORDINE.VALUE_FROM,
                                                    DATA_A:this.searchStatsStati.DATA_ORDINE.VALUE_TO
                                                },
            (response) => {
                this.arrayStatisticheStati = response.data;
            }); 
        },
        openAgente : function(agente){
            utils.ajax('nic/statistiche/agenti',{FK_ID_CENTRO_RICAVO:this.searchStatsAgenti.FK_ID_CENTRO_RICAVO.VALUE,
                                                    FK_ID_ARTICOLO:this.searchStatsAgenti.FK_ID_ARTICOLO.VALUE,
                                                    FK_ID_AGENTE:agente.FK_ID_AGENTE,
                                                    DATA_DA:this.searchStatsAgenti.DATA_ORDINE.VALUE_FROM,
                                                    DATA_A:this.searchStatsAgenti.DATA_ORDINE.VALUE_TO
                                                },
            (response) => {
                agente.ARRAY_CLIENTI = response.data;
            }); 
        },
        printStatisticheQuote : function(){
            utils.ajax('nic/statistiche/articoli/token',{FK_ID_CENTRO_RICAVO:this.searchStatsArticoli.FK_ID_CENTRO_RICAVO.VALUE,
                                                    FK_ID_ARTICOLO:this.searchStatsArticoli.FK_ID_ARTICOLO.VALUE,
                                                    FK_ID_CLIENTE:this.searchStatsArticoli.FK_ID_CLIENTE.VALUE,
                                                    FK_ID_AGENTE:this.searchStatsArticoli.FK_ID_AGENTE.VALUE,
                                                    DATA_DA:this.searchStatsArticoli.DATA_ORDINE.VALUE_FROM,
                                                    DATA_A:this.searchStatsArticoli.DATA_ORDINE.VALUE_TO}, (response) => {
                window.open(utils.BASE_API + "core/report?ID_REPORT=1001&token="+response.messaggio);
            });
        },
        getStyleAgente : function(agente){
            if (agente.hasOwnProperty("ARRAY_CLIENTI") && agente.ARRAY_CLIENTI.length > 0){
                return {background:"#ccc !important"};
            }
            return {};
        },
        esportaAgente : function(agente){
            utils.ajax('nic/statistiche/agenti/token',{FK_ID_CENTRO_RICAVO:this.searchStatsAgenti.FK_ID_CENTRO_RICAVO.VALUE,
                                                    FK_ID_ARTICOLO:this.searchStatsAgenti.FK_ID_ARTICOLO.VALUE,
                                                    FK_ID_AGENTE:agente.FK_ID_AGENTE,
                                                    DATA_DA:this.searchStatsAgenti.DATA_ORDINE.VALUE_FROM,
                                                    DATA_A:this.searchStatsAgenti.DATA_ORDINE.VALUE_TO,
                                                    IMPORTO:agente.IMPORTO,
                                                    IMPORTO_T1:agente.IMPORTO_T1,
                                                    VARIAZIONE_PERC:agente.VARIAZIONE_PERC}, (response) => {
                const idReport = agente.ID_AGENTE == 0 ? 1003 : 1002;
                window.open(utils.BASE_API + "core/report?ID_REPORT="+idReport+"&token="+response.messaggio);
            });
        }
    },
    watch:{
        'searchStatsAgentiPaginazione.FK_ID_AGENTE.VALUE' : function(){
            if (this.searchStatsAgentiPaginazione.FK_ID_AGENTE.VALUE == 0){
                this.searchStatsAgentiPaginazione.FK_ID_AGENTE.VALUE = "";
            }
        },
        'searchStatsAgenti.FK_ID_ARTICOLO.VALUE' : function(){
            this.getStatisticheAgenti();
        },
        'searchStatsAgenti.FK_ID_CENTRO_RICAVO.VALUE' : function(){
            this.getStatisticheAgenti();
        },
        'searchStatsAgenti.DATA_ORDINE.VALUE_FROM' : function(){
            this.getStatisticheAgenti();
        },
        'searchStatsAgenti.DATA_ORDINE.VALUE_TO' : function(){
            this.getStatisticheAgenti();
        },

        'searchStatsStati.DATA_ORDINE.VALUE_FROM' : function(){
            this.getStatisticheStati();
        },
        'searchStatsStati.DATA_ORDINE.VALUE_TO' : function(){
            this.getStatisticheStati();
        },


        'searchPagamenti.FK_ID_CLIENTE.VALUE' : function(){
            this.getStatistichePagamenti();
        },
        'searchPagamenti.FK_ID_AGENTE.VALUE' : function(){
            this.getStatistichePagamenti();
        },
        'searchPagamenti.FK_ID_ARTICOLO.VALUE' : function(){
            this.getStatistichePagamenti();
        },
        'searchPagamenti.FK_ID_CENTRO_RICAVO.VALUE' : function(){
            this.getStatistichePagamenti();
        },
        'searchStatsArticoli.FK_ID_CLIENTE.VALUE' : function(){
            this.getStatisticheArticoli();
        },
        'searchStatsArticoli.FK_ID_AGENTE.VALUE' : function(){
            this.getStatisticheArticoli();
        },
        'searchStatsArticoli.FK_ID_ARTICOLO.VALUE' : function(){
            this.getStatisticheArticoli();
        },
        'searchStatsArticoli.FK_ID_CENTRO_RICAVO.VALUE' : function(){
            this.getStatisticheArticoli();
        },
        'searchStatsArticoli.DATA_ORDINE.VALUE_FROM' : function(){
            this.getStatisticheArticoli();
        },
        'searchStatsArticoli.DATA_ORDINE.VALUE_TO' : function(){
            this.getStatisticheArticoli();
        },
        //searchStatsAgenti
    },
    computed: {
        paginatedListArticoli : function() {
            var arr = global.utils.inGrid(this.arrayStatisticheArticoli,this.searchStatsArticoliPaginazione);
            return arr;            
        },
        paginatedListAgenti : function() {
            var arr = global.utils.inGrid(this.arrayStatisticheAgenti,this.searchStatsAgentiPaginazione);
            return arr;            
        },
        paginatedListStati : function(){
            var arr = global.utils.inGrid(this.arrayStatisticheStati,this.searchStatsStatiPaginazione);
            return arr;            
        },
        getTotale : function(){
            var totale = 0;
            for (var i = 0 ; i < this.arrayStatisticheArticoli.length ; i++){
                totale += parseFloat(this.arrayStatisticheArticoli[i].IMPORTO);
            }
            return totale;
        }
    },
    beforeCreate : function(){
       global.utils.deltaGriglia = 80;
    },
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },
    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            global.utils.resizeContent();     
        }, 300);
    },
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },
    created : function(){
        this.arrayMesi = utils.clone(utils.arrayMesi);
        utils.ajax('nic/statistiche/startup',{}, (response) => {
            var arrayArticoli = utils.toSelect2(response.data.ARTICOLI,"ID_ARTICOLO","DESCRIZIONE");//.splice(0,0,{id:"",text:'Tutti gli articoli'});
            arrayArticoli.splice(0,0,{id:0,text:'Tutti gli articoli'});
            this.arrayArticoli = utils.clone(arrayArticoli);

            var arrayCentriRicavo = utils.toSelect2(response.data.CENTRI_RICAVO,"ID_CENTRO_RICAVO","DESCRIZIONE");
            arrayCentriRicavo.splice(0,0,{id:0,text:'Tutti i centri di ricavo'}); 
            this.arrayCentriRicavo = utils.clone(arrayCentriRicavo);

            var arrayClienti = utils.toSelect2(response.data.CLIENTI,"ID_CLIENTE","DENOMINAZIONE");
            arrayClienti.splice(0,0,{id:0,text:'Tutti i clienti'});
            this.arrayClienti = utils.clone(arrayClienti);

            var arrayAgenti = utils.toSelect2(response.data.AGENTI,"ID_AGENTE","DESCRIZIONE");
            arrayAgenti.splice(0,0,{id:0,text:'Tutti gli agenti'});
            this.arrayAgenti = utils.clone(arrayAgenti);

        });
        setTimeout(() => {
            $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
                var tab = $(e.target).data("tab");
                console.log(tab);
                switch(tab){
                    case 'statspagamenti':
                        this.getStatistichePagamenti();
                        break;
                    case 'statsArticoli':
                        this.getStatisticheArticoli();
                        break;
                    case 'statsAgenti':
                        this.getStatisticheAgenti();
                        break;
                    case 'statsStati':
                        this.getStatisticheStati();
                        break;

                }
            });            
        },300);
        utils.wait(()=>{
           this.searchStatsAgenti.DATA_ORDINE.VALUE_FROM = '01/01/'+new Date().getFullYear();
           this.searchStatsAgenti.DATA_ORDINE.VALUE_TO = this.filters.formatDate(new Date()); 
           this.searchStatsStati.DATA_ORDINE.VALUE_FROM = '01/01/'+new Date().getFullYear();
           this.searchStatsStati.DATA_ORDINE.VALUE_TO = this.filters.formatDate(new Date()); 
        });
       // this.searchStatsAgenti.DATA_ORDINE.VALUE_FROM = "01/02/2023";
        //this.$forceUpdate();
        //this.getStatisticheAgenti();
    }
}

</script>