const filters = {
    upperCase: (val) => {
        return val.toString().toUpperCase();
    },
    formattaImporto:(value) =>{
        if (value) {
            return global.utils.formatNumber(value);
        }
        else{
            return "0,00";
        }
    },
    formattaImportoInt:(value)=>{
        if (value) {
            return global.utils.formatNumberInt(value);
        }
        else{
            return "0";
        }
    },
    accorciaTesto : (value,length = 300, mostraPuntini = 1) =>{
        if (value) {
            if (value.toString().length > length){
                return value.toString().substring(0,length) + (mostraPuntini == 1 ? "..." : "");
            }
            else{
                return value.toString();
            }
        }
    },
    formatDate : (value) =>{
        if (value) {
            if (value.toString().search("/") > -1){
                return value;
            }
            return global.moment(String(value)).format('DD/MM/YYYY');
        }
    },
    formatDateReverse : (value) =>{
        if (value) {
            return value.toString().substr(6,4) + "-" + value.toString().substr(3,2) + "-" + value.toString().substr(0,2);
        }
    },
    formatDateSmart : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YY');
        }
    },
    formatDateTime : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YYYY - HH:mm');
        }
    },
    formatDateTimeSeconds : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YYYY - HH:mm:ss');
        }
    },
    formatTime: (value) => {
        if (value) {
            return moment(String('0000-01-01 '+ value)).format('HH:mm')
        }
    },
    formatTimefromDate: (value) => {
        if (value) {
            return moment(value).format('HH:mm:ss')
        }
    },
    formatDateYear: (value) => {
        if (value) {
            return moment(String(value)).format('YYYY')
        }
    },
    formatMonth: (value) => {
        if (value) {
            var mese = global.utils.array.select(global.utils.arrayMesi,"ID_MESE",value,1)[0];
            return mese.DESCRIZIONE;
        }
    },
    getCurrentDate: (value) => {
        var mese = new Date().getMonth()+1;
        if (mese < 10){
            mese = '0'+mese;
        }
        return new Date().getDate()+"/"+ mese +'/'+new Date().getFullYear()
    }

};

exports.filters = filters;



